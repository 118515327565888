export const state = () => ({
  theme: 'light',
  tippyOptions: {
    animation: 'scale',
    arrow: true,
    arrowType: 'round',
    interactive: true,
    maxWidth: '20rem',
    placement: 'top',
    theme: 'light'
  },
  currencies: [
    {
      code: 'usd'
    },
    {
      code: 'eur'
    },
    {
      code: 'cad'
    },
    {
      code: 'gbp'
    }
  ],
  steps: [20, 50, 100]
})

export const mutations = {
  setTheme(state, theme) {
    state.theme = theme
  }
}

export const actions = {
  nuxtServerInit({ commit, dispatch }) {
    const token = this.$cookies.get('auth.token')
    const user = this.$cookies.get('auth.user')
    const theme = this.$cookies.get('siteTheme')
    if (token !== undefined) {
      commit('auth/setUserToken', token)
    }
    if (user !== undefined) {
      commit('auth/setUser', user)
    }
    if (theme !== undefined) {
      commit('setTheme', theme)
    } else {
      commit('setTheme', 'light')
    }
  }
}

export const getters = {
  theme: state => state.theme,
  steps: state => state.steps,
  tippyOptions: state => state.tippyOptions,
  currencies: state => state.currencies
}
