export const state = () => ({
  messages: [],
  total: 0,
  loading: false,
  loadingMessage: false
})

export const mutations = {
  setMessages(state, messages) {
    state.messages = messages
  },
  setTotal(state, total) {
    state.total = total
  },
  addMessages(state, messages) {
    state.messages = state.messages.concat(messages)
  },
  addMessage(state, message) {
    state.messages.unshift(message)
  },
  setLoadingMessages(state, status) {
    state.loading = status
  },
  setLoadingMessage(state, status) {
    state.loadingMessage = status
  },
  removeMessage(state, mid) {
    state.messages = state.messages.filter(itm => itm.mid !== mid)
  },
  removeMessageLike(state, mid) {
    const needableIndex = state.messages.findIndex(itm => itm.mid === mid)
    if (needableIndex !== -1) {
      state.messages[needableIndex].likes -= 1
      state.messages[needableIndex].like_date = null
    }
    if (state.messages[needableIndex].likes < 0) {
      state.messages[needableIndex].likes = 0
    }
  },
  addMessageLike(state, mid) {
    const needableIndex = state.messages.findIndex(itm => itm.mid === mid)
    if (needableIndex !== -1) {
      state.messages[needableIndex].likes += 1
      state.messages[needableIndex].like_date = new Date()
    }
  }
}

export const actions = {
  async fetchMessages({
    commit
  }, payload) {
    commit('setLoadingMessages', true)
    const request = await this.$generateApiRequest()
    const messages = await request.$get('chat', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : null,
        uid: payload.uid !== undefined ? payload.uid : null,
        pid: payload.pid !== undefined ? payload.pid : null,
        type: payload.type !== undefined ? payload.type : null,
        isReply: payload.isReply !== undefined ? payload.isReply : null,
        author: payload.author !== undefined ? payload.author : null
      }
    })
    if (payload.page === 1) {
      commit('setMessages', messages.answer.messages)
    } else {
      commit('addMessages', messages.answer.messages)
    }
    commit('setTotal', messages.total)
    commit('setLoadingMessages', false)
  },
  async sendMessage({
    commit
  }, payload) {
    commit('setLoadingMessage', true)
    const request = await this.$generateApiRequest()
    const response = await request.$post('acc/chat/create', payload)
    commit('addMessage', response.answer.message)
    commit('setLoadingMessage', false)
  },
  async removeMessage({
    commit
  }, payload) {
    commit('setLoadingMessage', true)
    const request = await this.$generateApiRequest()
    await request.$delete(`acc/chat/${payload.id}/remove`, payload)
    commit('removeMessage', payload.id)
    commit('setLoadingMessage', false)
  },
  async removeMessageLike({
    commit
  }, payload) {
    commit('setLoadingMessage', true)
    const request = await this.$generateApiRequest()
    await request.$delete(`acc/chat/likes/${payload.id}/remove`, payload)
    commit('removeMessageLike', payload.id)
    commit('setLoadingMessage', false)
  },
  async addMessageLike({
    commit
  }, payload) {
    commit('setLoadingMessage', true)
    const request = await this.$generateApiRequest()
    await request.$post(`acc/chat/likes/${payload.id}/add`, payload)
    commit('addMessageLike', payload.id)
    commit('setLoadingMessage', false)
  },
  async reportMessage({
    commit
  }, payload) {
    commit('setLoadingMessage', true)
    const request = await this.$generateApiRequest()
    await request.$post(`acc/chat/${payload.mid}/report`, payload)
    commit('setLoadingMessage', false)
  }
}

export const getters = {
  messages: state => state.messages,
  total: state => state.total,
  loading: state => state.loading,
  loadingMessage: state => state.loadingMessage
}
