export const state = () => ({
  blockchains: [],
  total: 0,
  loading: false
})

export const mutations = {
  setBlockchains(state, blockchains) {
    state.blockchains = blockchains
  },
  setTotal(state, total) {
    state.total = total
  },
  setLoadingBlockchains(state, status) {
    state.loading = status
  }
}

export const actions = {
  async fetchBlockchains({
    commit
  }, payload) {
    commit('setLoadingBlockchains', true)
    const request = await this.$generateApiRequest()
    const chains = await request.$get('blockchains', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'price_desc',
        search: payload.search !== undefined ? payload.search : ''
      }
    })
    commit('setBlockchains', chains.answer.blockchains)
    commit('setTotal', chains.total)
    commit('setLoadingBlockchains', false)
  }
}

export const getters = {
  blockchains: state => state.blockchains,
  total: state => state.total,
  loading: state => state.loading
}
