export const state = () => ({
  portfolios: [],
  totalPortfolios: 0,
  assets: [],
  transactions: [],
  totalAssets: 0,
  totalTransactions: 0,
  loadingAsset: null,
  loadingAssets: false,
  loadingTransactions: false,
  portfolio: null,
  loadingPortfolio: null,
  transaction: null,
  loadingTransaction: null,
  stats: [],
  loadingStats: false,
  loadingPortfolios: false
})

export const mutations = {
  setAssets(state, assets) {
    state.assets = assets
  },
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setStats(state, stats) {
    state.stats = stats !== null ? stats : []
  },
  setPortfolios(state, portfolios) {
    state.portfolios = portfolios
  },
  setTotalAssets(state, totalAssets) {
    state.totalAssets = totalAssets
  },
  setTotalTransactions(state, totalTransactions) {
    state.totalTransactions = totalTransactions
  },
  setTotalPortfolios(state, totalPortfolios) {
    state.totalPortfolios = totalPortfolios
  },
  addAssets(state, assets) {
    state.assets = state.assets.concat(assets)
  },
  addPortfolios(state, portfolios) {
    state.portfolios = state.portfolios.concat(portfolios)
  },
  removeAsset(state, aid) {
    state.assets = state.assets.filter(itm => itm.id !== aid)
  },
  addTransactions(state, transactions) {
    state.transactions = state.transactions.concat(transactions)
  },
  addTransaction(state, transaction) {
    state.transactions.unshift(transaction)
  },
  setTransaction(state, transaction) {
    state.transaction = transaction
  },
  setTransactionInList(state, transaction) {
    const newItem = state.transactions.findIndex(itm => itm.id === transaction.id)
    state.transactions[newItem] = transaction
  },
  removeTransaction(state, tid) {
    state.transactions = state.transactions.filter(itm => itm.id !== tid)
  },
  setPortfolio(state, portfolio) {
    state.portfolio = portfolio
  },
  setLoadingTransactions(state, status) {
    state.loadingTransactions = status
  },
  setLoadingAssets(state, status) {
    state.loadingAssets = status
  },
  setLoadingAsset(state, status) {
    state.loadingAsset = status
  },
  setLoadingPortfolio(state, status) {
    state.loadingPortfolio = status
  },
  setLoadingTransaction(state, status) {
    state.loadingTransaction = status
  },
  setLoadingStats(state, status) {
    state.loadingStats = status
  },
  setLoadingPortfolios(state, status) {
    state.loadingPortfolios = status
  }
}

export const actions = {
  async fetchAssets({
    commit
  }, payload) {
    commit('setLoadingAssets', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/portfolio/assets', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 50,
        orderField: payload.orderField !== undefined ? payload.orderField : 'amount_desc'
      }
    })
    commit('setAssets', response.answer.assets)
    commit('setTotalAssets', response.answer.total)
    commit('setLoadingAssets', false)
  },
  async fetchTransactions({
    commit
  }, payload) {
    commit('setLoadingTransactions', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/transactions', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 50,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        asset: payload.aid !== undefined ? payload.aid : 'created_at_desc'
      }
    })
    commit('setTransactions', response.answer.transactions)
    commit('setTotalTransactions', response.answer.total)
    commit('setLoadingTransactions', false)
  },
  async fetchPortfolios({
    commit
  }, payload) {
    commit('setLoadingPortfolios', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/portfolio', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 50,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        userId: payload.uid !== undefined ? payload.uid : 'self'
      }
    })
    commit('setPortfolios', response.answer.portfolios)
    commit('setTotalPortfolios', response.answer.total)
    commit('setLoadingPortfolios', false)
  },
  async fetchPortfolio({
    commit
  }, payload) {
    commit('setLoadingPortfolio', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/portfolio/show')
    commit('setPortfolio', response.answer.portfolio)
    commit('setLoadingPortfolio', false)
  },
  async fetchPortfolioStats({
    commit
  }, payload) {
    commit('setLoadingStats', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/portfolio/stats', {
      params: {
        period: payload.period !== undefined ? payload.period : '1d'
      }
    })
    commit('setStats', response.answer.stats)
    commit('setLoadingStats', false)
  },
  async deleteAsset({
    commit
  }, payload) {
    if (payload.aid !== undefined && payload.aid !== null && !isNaN(payload.aid)) {
      commit('setLoadingAsset', payload.aid)
      const request = await this.$generateApiRequest()
      await request.$delete(`acc/portfolio/assets/${payload.aid}/delete`)
      commit('removeAsset', payload.aid)
      commit('setLoadingAsset', null)
    }
  },
  async deleteTransaction({
    commit
  }, payload) {
    if (payload.tid !== undefined && payload.tid !== null && !isNaN(payload.tid)) {
      commit('setLoadingTransaction', payload.tid)
      const request = await this.$generateApiRequest()
      await request.$delete(`acc/transactions/${payload.tid}`)
      commit('removeTransaction', payload.tid)
      commit('setLoadingTransaction', null)
    }
  },
  async fetchTransaction({
    commit
  }, payload) {
    commit('setLoadingTransaction', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get(`acc/transactions/${payload.id}`)
    commit('setTransaction', response.answer.transaction)
    commit('setLoadingTransaction', false)
  },
  async createTransaction({
    commit
  }, payload) {
    if (payload.tid !== undefined && payload.tid !== null && !isNaN(payload.tid)) {
      commit('setLoadingTransaction', 0)
      const request = await this.$generateApiRequest()
      const response = await request.$post('acc/transactions', payload)
      commit('addTransaction', response.answer.transaction)
      commit('setLoadingTransaction', null)
    }
  },
  async updateTransaction({
    commit
  }, payload) {
    if (payload.id !== undefined && payload.id !== null && !isNaN(payload.id)) {
      commit('setLoadingTransaction', payload.id)
      const request = await this.$generateApiRequest()
      const response = await request.$put(`acc/transactions/${payload.id}`, payload)
      commit('setTransactionInList', response.answer.transaction)
      commit('setLoadingTransaction', null)
    }
  }
}

export const getters = {
  portfolios: state => state.portfolios,
  assets: state => state.assets,
  transactions: state => state.transactions,
  stats: state => state.stats,
  portfolio: state => state.portfolio,
  transaction: state => state.transaction,
  totalAssets: state => state.totalAssets,
  totalPortfolios: state => state.totalPortfolios,
  totalTransactions: state => state.totalTransactions,
  loadingAsset: state => state.loadingAsset,
  loadingAssets: state => state.loadingAssets,
  loadingTransactions: state => state.loadingTransactions,
  loadingPortfolio: state => state.loadingPortfolio,
  loadingTransaction: state => state.loadingTransaction,
  loadingStats: state => state.loadingStats,
  loadingPortfolios: state => state.loadingPortfolios
}
