export const state = () => ({
  airdrops: [],
  total: 0,
  loading: false,
  airdrop: null,
  airdropLoading: false,
  related: []
})

export const mutations = {
  setAirdrops(state, airdrops) {
    state.airdrops = airdrops
  },
  setTotal(state, total) {
    state.total = total
  },
  addAirdrops(state, airdrops) {
    state.airdrops = state.airdrops.concat(airdrops)
  },
  setAirdrop(state, airdrop) {
    state.airdrop = airdrop
  },
  removeAirdrop(state, aid) {
    state.airdrops = state.airdrops.filter(itm => itm.id !== aid)
  },
  setRelated(state, related) {
    state.related = related
  },
  setAirdropImage(state, image) {
    if (state.airdrop !== null) {
      state.airdrop.image = image
    }
  },
  setLoadingAirdrops(state, status) {
    state.loading = status
  },
  setLoadingAirdrop(state, status) {
    state.airdropLoading = status
  }
}

export const actions = {
  async fetchAirdrops({
    commit
  }, payload) {
    commit('setLoadingAirdrops', true)
    const request = await this.$generateApiRequest()
    const airdrops = await request.$get('airdrops', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'ends_asc',
        uid: payload.uid !== undefined ? payload.uid : null,
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        dateFrom: payload.dateFrom !== undefined ? payload.dateFrom : null,
        dateTo: payload.dateTo !== undefined ? payload.dateTo : null,
        winnersFrom: payload.winnersFrom !== undefined ? payload.winnersFrom : null,
        winnersTo: payload.winnersTo !== undefined ? payload.winnersTo : null,
        totalFrom: payload.totalFrom !== undefined ? payload.totalFrom : null,
        totalTo: payload.totalTo !== undefined ? payload.totalTo : null,
        search: payload.search !== undefined ? payload.search : '',
        type: payload.type !== undefined ? payload.type : '',
        status: payload.status !== undefined ? payload.status : ''
      }
    })
    commit('setAirdrops', airdrops.answer.airdrops)
    commit('setTotal', airdrops.answer.total)
    commit('setLoadingAirdrops', false)
  },
  async fetchMyAirdrops({
    commit
  }, payload) {
    commit('setLoadingAirdrops', true)
    const request = await this.$generateApiRequest()
    const airdrops = await request.$get('acc/airdrops', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        uid: payload.uid !== undefined ? payload.uid : null,
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        dateFrom: payload.dateFrom !== undefined ? payload.dateFrom : null,
        dateTo: payload.dateTo !== undefined ? payload.dateTo : null,
        winnersFrom: payload.winnersFrom !== undefined ? payload.winnersFrom : null,
        winnersTo: payload.winnersTo !== undefined ? payload.winnersTo : null,
        totalFrom: payload.totalFrom !== undefined ? payload.totalFrom : null,
        totalTo: payload.totalTo !== undefined ? payload.totalTo : null,
        search: payload.search !== undefined ? payload.search : '',
        type: payload.type !== undefined ? payload.type : '',
        status: payload.status !== undefined ? payload.status : ''
      }
    })
    commit('setAirdrops', airdrops.answer.airdrops)
    commit('setTotal', airdrops.answer.total)
    commit('setLoadingAirdrops', false)
  },
  async fetchAirdrop({
    commit
  }, payload) {
    commit('setLoadingAirdrop', true)
    const request = await this.$generateApiRequest()
    const airdrop = await request.$get('airdrops/' + payload.uri, {})
    commit('setAirdrop', airdrop.answer.airdrop)
    commit('setLoadingAirdrop', false)
  },
  async fetchAirdropEdit({
    commit
  }, payload) {
    commit('setLoadingAirdrop', true)
    const request = await this.$generateApiRequest()
    const airdrop = await request.$get('acc/airdrops/' + payload.id, {})
    commit('setAirdrop', airdrop.answer.airdrop)
    commit('setLoadingAirdrop', false)
  },
  async createAirdrop({
    commit
  }, payload) {
    commit('setLoadingAirdrop', true)
    const request = await this.$generateApiRequest()
    const response = await request.$post('acc/airdrops', payload)
    commit('setAirdrop', response.answer.airdrop)
    commit('setLoadingAirdrop', false)
  },
  async updateAirdrop({
    commit
  }, payload) {
    commit('setLoadingAirdrop', true)
    const request = await this.$generateApiRequest()
    const response = await request.$put('acc/airdrops/' + payload.id, payload)
    commit('setAirdrop', response.answer.airdrop)
    commit('setLoadingAirdrop', false)
  },
  async uploadImage({
    commit
  }, payload) {
    commit('setLoadingAirdrop', true)
    return await new Promise((resolve, reject) => {
      this.$upload.$post('acc/airdrops/upload', payload) // payload shoud be FormData()
        .then((response) => {
          if (response.answer !== undefined) {
            commit('setAirdropImage', response.answer.path)
          } else {
            commit('setAirdropImage', null)
          }
          commit('setLoadingAirdrop', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoadingAirdrop', false)
          if (error.response && error.response.status < 500) {
            const errors = error.response.data.errors
            reject(this.getErrorsText(errors))
          } else {
            reject(error.message)
          }
          reject(error.config)
        })
    })
  },
  async deleteAirdrop({
    commit
  }, payload) {
    commit('setLoadingAirdrop', true)
    const request = await this.$generateApiRequest()
    await request.$delete('acc/airdrops/' + payload.id, {})
    commit('removeAirdrop', payload.id)
    commit('setLoadingAirdrop', false)
  },
  async checkAirdropUri({
    commit
  }, payload) {
    commit('setLoadingAirdrop', true)
    const request = await this.$generateApiRequest()
    await request.$get('acc/airdrops/check-uri', {
      params: {
        uri: payload.uri !== undefined ? payload.uri : ''
      }
    })
    commit('setLoadingAirdrop', false)
  }
}

export const getters = {
  airdrops: state => state.airdrops,
  total: state => state.total,
  loading: state => state.loading,
  airdrop: state => state.airdrop,
  related: state => state.related,
  airdropLoading: state => state.airdropLoading
}
