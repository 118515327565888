export const state = () => ({
  transactions: [],
  distributors: [],
  banners: [],
  totalTransactions: 0,
  totalDistributors: 0,
  totalBanners: 0,
  loadingTransactions: false,
  loadingDistributors: false,
  loadingBanners: false,
  banner: null,
  bannerLoading: false,
  distributor: null,
  distributorLoading: false,
  cabinet: null,
  cabinetLoading: false
})

export const mutations = {
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setTotalTransactions(state, total) {
    state.totalTransactions = total
  },
  setLoadingTransactions(state, status) {
    state.loadingTransactions = status
  },
  removeTransaction(state, id) {
    state.transactions = state.transactions.filter(itm => itm.id !== id)
  },
  setDistributors(state, distributors) {
    state.distributors = distributors
  },
  setDistributor(state, distributor) {
    state.distributor = distributor
  },
  setTotalDistributors(state, total) {
    state.totalDistributors = total
  },
  setLoadingDistributors(state, status) {
    state.loadingDistributors = status
  },
  removeDistributor(state, id) {
    state.distributors = state.distributors.filter(itm => itm.id !== id)
  },
  setBanners(state, banners) {
    state.banners = banners
  },
  setBanner(state, banner) {
    state.banner = banner
  },
  setTotalBanners(state, total) {
    state.totalBanners = total
  },
  setLoadingBanners(state, status) {
    state.loadingBanners = status
  },
  removeBanner(state, id) {
    state.banners = state.banners.filter(itm => itm.id !== id)
  },
  removeCabinet(state) {
    state.cabinet = null
  },
  setBannerImage(state, imageData) {
    if (
      state.banner !== null &&
      imageData !== null &&
      imageData.size !== null &&
      imageData.path !== null &&
      state.banner['b' + imageData.size] !== undefined
    ) {
      state.banner['b' + imageData.size] = imageData.path
    }
  },
  setLoadingDistributor(state, status) {
    state.distributorLoading = status
  },
  setCabinet(state, cabinet) {
    state.cabinet = cabinet
  },
  setLoadingCabinet(state, status) {
    state.cabinetLoading = status
  },
  setLoadingBanner(state, status) {
    state.bannerLoading = status
  }
}

export const actions = {
  async fetchTransactions({
    commit
  }, payload) {
    commit('setLoadingTransactions', true)
    const request = await this.$generateApiRequest()
    const txs = await request.$get('acc/cabinets/transactions', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        cid: payload.cid !== undefined ? payload.cid : null,
        uid: payload.uid !== undefined ? payload.uid : null,
        bid: payload.bid !== undefined ? payload.bid : null,
        target: payload.target !== undefined ? payload.target : null,
        flow: payload.flow !== undefined ? payload.flow : null,
        source: payload.source !== undefined ? payload.source : null,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setTransactions', txs.answer.transactions)
    commit('setTotalTransactions', txs.total)
    commit('setLoadingTransactions', false)
  },
  async fetchDistributors({
    commit
  }, payload) {
    commit('setLoadingDistributors', true)
    const request = await this.$generateApiRequest()
    const distributors = await request.$get('acc/distributors', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        cid: payload.cid !== undefined ? payload.cid : null,
        type: payload.type !== undefined ? payload.type : null,
        size: payload.size !== undefined ? payload.size : null,
        ad_type: payload.ad_type !== undefined ? payload.ad_type : null,
        is_active: payload.is_active !== undefined ? payload.is_active : null,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setDistributors', distributors.answer.distributors)
    commit('setTotalDistributors', distributors.answer.total)
    commit('setLoadingDistributors', false)
  },
  async fetchDistributor({
    commit
  }, payload) {
    commit('setLoadingDistributor', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/distributors/' + payload.id, {})
    commit('setDistributor', response.answer.distributor)
    commit('setLoadingDistributor', false)
  },
  async createDistributor({
    commit
  }, payload) {
    commit('setLoadingDistributor', true)
    const request = await this.$generateApiRequest()
    const response = await request.$post('acc/distributors', payload)
    commit('setDistributor', response.answer.distributor)
    commit('setLoadingDistributor', false)
  },
  async updateDistributor({
    commit
  }, payload) {
    commit('setLoadingDistributor', true)
    const request = await this.$generateApiRequest()
    const response = await request.$put('acc/distributors/' + payload.id, payload)
    commit('setDistributor', response.answer.distributor)
    commit('setLoadingDistributor', false)
  },
  async destroyDistributor({
    commit
  }, payload) {
    commit('setLoadingDistributor', true)
    const request = await this.$generateApiRequest()
    await request.$delete('acc/distributors/' + payload.id, payload)
    commit('removeDistributor', payload.id)
    commit('setLoadingDistributor', false)
  },
  async fetchBanners({
    commit
  }, payload) {
    commit('setLoadingBanners', true)
    const request = await this.$generateApiRequest()
    const banners = await request.$get('acc/banners', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        cid: payload.cid !== undefined ? payload.cid : null,
        type: payload.type !== undefined ? payload.type : null,
        size: payload.size !== undefined ? payload.size : null,
        category: payload.category !== undefined ? payload.category : null,
        target: payload.target !== undefined ? payload.target : null,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setBanners', banners.answer.banners)
    commit('setTotalBanners', banners.answer.total)
    commit('setLoadingBanners', false)
  },
  async fetchBanner({
    commit
  }, payload) {
    commit('setLoadingBanner', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/banners/' + payload.id, {})
    commit('setBanner', response.answer.banner)
    commit('setLoadingBanner', false)
  },
  async createBanner({
    commit
  }, payload) {
    commit('setLoadingBanner', true)
    const request = await this.$generateApiRequest()
    const response = await request.$post('acc/banners', payload)
    commit('setBanner', response.answer.banner)
    commit('setLoadingBanner', false)
  },
  async updateBanner({
    commit
  }, payload) {
    commit('setLoadingBanner', true)
    const request = await this.$generateApiRequest()
    const response = await request.$put('acc/banners/' + payload.id, payload)
    commit('setBanner', response.answer.banner)
    commit('setLoadingBanner', false)
  },
  async destroyBanner({
    commit
  }, payload) {
    commit('setLoadingBanner', true)
    const request = await this.$generateApiRequest()
    await request.$delete('acc/banners/' + payload.id, payload)
    commit('removeBanner', payload.id)
    commit('setLoadingBanner', false)
  },
  async uploadBanner({
    commit
  }, payload) {
    commit('setLoadingBanner', true)
    return await new Promise((resolve, reject) => {
      this.$upload.$post('acc/banners/upload', payload) // payload shoud be FormData()
        .then((response) => {
          if (response.answer !== undefined) {
            commit('setBannerImage', {
              size: payload.get('size'),
              path: response.answer.path
            })
          } else {
            commit('setBannerImage', null)
          }
          commit('setLoadingBanner', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoadingBanner', false)
          if (error.response && error.response.status < 500) {
            const errors = error.response.data.errors
            reject(this.getErrorsText(errors))
          } else {
            reject(error.message)
          }
          reject(error.config)
        })
    })
  },
  async fetchCabinets({
    commit
  }, payload) {
    commit('setLoadingCabinet', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/cabinets', {
      params: {
        page: 1,
        step: 1
      }
    })
    commit('setCabinet', response.answer.cabinets[0] !== undefined ? response.answer.cabinets[0] : null)
    commit('setLoadingCabinet', false)
  },
  async fetchCabinet({
    commit
  }, payload) {
    commit('setLoadingCabinet', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/cabinets/' + payload.id, {})
    commit('setCabinet', response.answer.cabinet)
    commit('setLoadingCabinet', false)
  },
  async createCabinet({
    commit
  }, payload) {
    commit('setLoadingCabinet', true)
    const request = await this.$generateApiRequest()
    const response = await request.$post('acc/cabinets', payload)
    commit('setCabinet', response.answer.cabinet)
    commit('setLoadingCabinet', false)
  },
  async updateCabinet({
    commit
  }, payload) {
    commit('setLoadingCabinet', true)
    const request = await this.$generateApiRequest()
    const response = await request.$put('acc/cabinets/' + payload.id, payload)
    commit('setCabinet', response.answer.cabinet)
    commit('setLoadingCabinet', false)
  },
  async destroyCabinet({
    commit
  }, payload) {
    commit('setLoadingCabinet', true)
    const request = await this.$generateApiRequest()
    await request.$delete('acc/cabinets/' + payload.id, payload)
    commit('removeCabinet')
    commit('setLoadingCabinet', false)
  }
}

export const getters = {
  transactions: state => state.transactions,
  distributors: state => state.distributors,
  banners: state => state.banners,
  totalTransactions: state => state.totalTransactions,
  totalDistributors: state => state.totalDistributors,
  totalBanners: state => state.totalBanners,
  loadingTransactions: state => state.loadingTransactions,
  loadingDistributors: state => state.loadingDistributors,
  loadingBanners: state => state.loadingBanners,
  transaction: state => state.transaction,
  transactionLoading: state => state.transactionLoading,
  distributor: state => state.distributor,
  distributorLoading: state => state.distributorLoading,
  banner: state => state.banner,
  bannerLoading: state => state.bannerLoading,
  cabinet: state => state.cabinet,
  cabinetLoading: state => state.cabinetLoading
}
