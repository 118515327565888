export const state = () => ({
  glossaries: [],
  total: 0,
  loading: false,
  glossary: null,
  glossaryLoading: false
})

export const mutations = {
  setGlossaries(state, glossaries) {
    state.glossaries = glossaries
  },
  setTotal(state, total) {
    state.total = total
  },
  addGlossaries(state, glossaries) {
    state.glossaries = state.glossaries.concat(glossaries)
  },
  setGlossary(state, glossary) {
    state.glossary = glossary
  },
  setLoadingGlossaries(state, status) {
    state.loading = status
  },
  setLoadingGlossary(state, status) {
    state.glossaryLoading = status
  }
}

export const actions = {
  async fetchGlossaries({
    commit
  }, payload) {
    commit('setLoadingGlossaries', true)
    const request = await this.$generateApiRequest()
    const glossaries = await request.$get('glossary', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'title_asc',
        search: payload.search !== undefined ? payload.search : ''
      }
    })
    if (payload.page === 1) {
      commit('setGlossaries', glossaries.answer.glossaries)
    } else {
      commit('addGlossaries', glossaries.answer.glossaries)
    }
    commit('setTotal', glossaries.total)
    commit('setLoadingGlossaries', false)
  },
  async fetchGlossary({
    commit
  }, payload) {
    commit('setLoadingGlossary', true)
    const request = await this.$generateApiRequest()
    const glossary = await request.$get('glossary/' + payload.uri, {})
    commit('setGlossary', glossary.answer.glossary)
    commit('setLoadingGlossary', false)
  }
}

export const getters = {
  glossaries: state => state.glossaries,
  total: state => state.total,
  loading: state => state.loading,
  glossary: state => state.glossary,
  glossaryLoading: state => state.glossaryLoading
}
