export const state = () => ({
  modalOpen: false,
  commonOpen: false,
  leaveSiteOpen: false,
  filterOpen: false,
  reportOpen: false,
  fiatsListOpen: false,
  investOpen: false,
  paymentCreationOpen: false,
  topupOpen: false,
  modalCustomOpen: false
})

export const mutations = {
  setModalOpen(state, status) {
    state.modalOpen = status
    checkOverflow(status)
  },
  setCommonOpen(state, status) {
    state.commonOpen = status
    checkOverflow(status)
  },
  setFilterOpen(state, status) {
    state.filterOpen = status
    checkOverflow(status)
  },
  setReportOpen(state, status) {
    state.reportOpen = status
    checkOverflow(status)
  },
  setLeaveSiteOpen(state, status) {
    state.leaveSiteOpen = status
    checkOverflow(status)
  },
  setFiatsListOpen(state, status) {
    state.fiatsListOpen = status
    checkOverflow(status)
  },
  setInvestOpen(state, status) {
    state.investOpen = status
    checkOverflow(status)
  },
  setPaymentCreationOpen(state, status) {
    state.paymentCreationOpen = status
    checkOverflow(status)
  },
  setTopupOpen(state, status) {
    state.topupOpen = status
    checkOverflow(status)
  },
  setModalCustomState(state, status) {
    state.modalCustomOpen = status
    checkOverflow(status)
  },
  closeAll(state) {
    state.filterOpen = false
    state.modalOpen = false
    state.commonOpen = false
    state.leaveSiteOpen = false
    state.reportOpen = false
    state.fiatsListOpen = false
    state.investOpen = false
    state.modalCustomOpen = false
    state.paymentCreationOpen = false
    state.topupOpen = false
    checkOverflow(false)
  }
}

export const actions = {
  changeModalState({
    commit
  }, payload) {
    commit('setModalOpen', payload.state)
  },
  changeCommonState({
    commit
  }, payload) {
    commit('setCommonOpen', payload.state)
  },
  changeFilterState({
    commit
  }, payload) {
    commit('setFilterOpen', payload.state)
  },
  changeLeaveSiteState({
    commit
  }, payload) {
    commit('setLeaveSiteOpen', payload.state)
  },
  changeReportState({
    commit
  }, payload) {
    commit('setReportOpen', payload.state)
  },
  changeFiatsListState({
    commit
  }, payload) {
    commit('setFiatsListOpen', payload.state)
  },
  changeInvestState({
    commit
  }, payload) {
    commit('setInvestOpen', payload.state)
  },
  changePaymentCustomState({
    commit
  }, payload) {
    commit('setPaymentCreationOpen', payload.state)
  },
  changeTopupState({
    commit
  }, payload) {
    commit('setTopupOpen', payload.state)
  },
  changeModalCustomState({
    commit
  }, payload) {
    commit('setModalCustomState', payload.state)
  }
}

export const getters = {
  modalOpen: state => state.modalOpen,
  commonOpen: state => state.commonOpen,
  filterOpen: state => state.filterOpen,
  leaveSiteOpen: state => state.leaveSiteOpen,
  reportOpen: state => state.reportOpen,
  fiatsListOpen: state => state.fiatsListOpen,
  investOpen: state => state.investOpen,
  paymentCreationOpen: state => state.paymentCreationOpen,
  topupOpen: state => state.topupOpen,
  modalCustomOpen: state => state.modalCustomOpen
}

function checkOverflow(status) {
  if (typeof document !== 'undefined') {
    const menuElement = document.querySelector('.menu')
    if (menuElement === null || (menuElement !== null && !menuElement.classList.contains('active'))) {
      if (status === true) {
        document.querySelector('body').classList.add('active')
      } else {
        document.querySelector('body').classList.remove('active')
      }
    }
  }
}
