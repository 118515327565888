export const state = () => ({
  investments: [],
  totalPurchaes: 0,
  total: 0,
  investmentsLoading: false,
  investmentLoading: false
})

export const mutations = {
  setInvestments(state, investments) {
    state.investments = investments
  },
  setTotal(state, total) {
    state.total = total
  },
  setTotalPurchaes(state, total) {
    state.totalPurchaes = total
  },
  addInvestment(state, investment) {
    state.investments.unshift(investment)
  },
  addInvestments(state, investments) {
    state.investments = state.investments.concat(investments)
  },
  setLoadingInvestments(state, status) {
    state.investmentsLoading = status
  },
  setLoadingInvestment(state, status) {
    state.investmentLoading = status
  }
}

export const actions = {
  async fetchInvestments({
    commit
  }, payload) {
    commit('setLoadingInvestments', true)
    const request = await this.$generateApiRequest()
    const transactions = await request.$get('acc/investments', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 25,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        method: payload.method !== undefined ? payload.method : '',
        search: payload.search !== undefined ? payload.search : ''
      }
    })
    commit('setInvestments', transactions.answer.investments)
    commit('setTotal', transactions.total)
    commit('setTotalPurchaes', transactions.answer.purchased)
    commit('setLoadingInvestments', false)
  },
  async addInvestment({
    commit
  }, payload) {
    commit('setLoadingInvestment', true)
    const request = await this.$generateApiRequest()
    const investment = await request.$post('acc/investments', payload)
    commit('addInvestment', investment.answer.investment)
    commit('setLoadingInvestment', false)
    return investment.answer.investment
  },
  async getBonuses({
    commit
  }, payload) {
    commit('setLoadingInvestment', true)
    const request = await this.$generateApiRequest()
    const investment = await request.$get('acc/investments/bonus')
    commit('setLoadingInvestment', false)
    return investment.answer.amount
  },
  async getPaymentSystemsList({
    commit
  }, payload) {
    commit('setLoadingInvestment', true)
    const request = await this.$generateApiRequest()
    const list = await request.$get('acc/investments/ps')
    commit('setLoadingInvestment', false)
    return list.answer.ps
  },
  async sendPaybackQuery({
    commit
  }, payload) {
    commit('setLoadingInvestment', true)
    const request = await this.$generateApiRequest()
    const list = await request.$post('acc/investments/payback', {
      ps: payload.ps !== undefined ? payload.ps : null,
      sum: payload.sum !== undefined ? payload.sum : null,
      acc_num: payload.acc_num !== undefined ? payload.acc_num : null,
      description: payload.description !== undefined ? payload.description : null
    })
    commit('setLoadingInvestment', false)
    return list.answer.ps
  }
}

export const getters = {
  investments: state => state.investments,
  total: state => state.total,
  totalPurchaes: state => state.totalPurchaes,
  investmentsLoading: state => state.investmentsLoading,
  investmentLoading: state => state.investmentLoading
}
