export const state = () => ({
  icos: [],
  total: 0,
  loading: false,
  ico: null,
  icoLoading: false,
  related: []
})

export const mutations = {
  setIcos(state, icos) {
    state.icos = icos
  },
  setTotal(state, total) {
    state.total = total
  },
  addIcos(state, icos) {
    state.icos = state.icos.concat(icos)
  },
  setIco(state, ico) {
    state.ico = ico
  },
  setIcoScreenshots(state, screenshots) {
    state.ico.screenshots = screenshots
  },
  setRelated(state, related) {
    state.related = related
  },
  removeIco(state, id) {
    state.icos = state.icos.filter(itm => itm.id !== id)
  },
  setLoadingIcos(state, status) {
    state.loading = status
  },
  setLoadingIco(state, status) {
    state.icoLoading = status
  },
  setIcoImage(state, image) {
    if (state.ico !== null) {
      state.ico.image = image
    }
  }
}

export const actions = {
  async fetchIcos({
    commit
  }, payload) {
    commit('setLoadingIcos', true)
    const request = await this.$generateApiRequest()
    const icos = await request.$get('ico', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        status: payload.status !== undefined ? payload.status : null,
        category: payload.category !== undefined ? payload.category : null,
        type: payload.type !== undefined ? payload.type : null,
        dateFrom: payload.dateFrom !== undefined ? payload.dateFrom : null,
        dateTo: payload.dateTo !== undefined ? payload.dateTo : null,
        priceFrom: payload.priceFrom !== undefined ? payload.priceFrom : null,
        priceTo: payload.priceTo !== undefined ? payload.priceTo : null,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setIcos', icos.answer.icos)
    commit('setTotal', icos.answer.total)
    commit('setLoadingIcos', false)
  },
  async fetchMyIcos({
    commit
  }, payload) {
    commit('setLoadingIcos', true)
    const request = await this.$generateApiRequest()
    const icos = await request.$get('acc/ico', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        status: payload.status !== undefined ? payload.status : null,
        category: payload.category !== undefined ? payload.category : null,
        type: payload.type !== undefined ? payload.type : null,
        dateFrom: payload.dateFrom !== undefined ? payload.dateFrom : null,
        dateTo: payload.dateTo !== undefined ? payload.dateTo : null,
        priceFrom: payload.priceFrom !== undefined ? payload.priceFrom : null,
        priceTo: payload.priceTo !== undefined ? payload.priceTo : null,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setIcos', icos.answer.icos)
    commit('setTotal', icos.answer.total)
    commit('setLoadingIcos', false)
  },
  async fetchIco({
    commit
  }, payload) {
    commit('setLoadingIco', true)
    const request = await this.$generateApiRequest()
    const ico = await request.$get('ico/' + payload.uri, {})
    commit('setIco', ico.answer.ico)
    commit('setIcoScreenshots', ico.answer.screenshots)
    commit('setLoadingIco', false)
  },
  async fetchIcoEdit({
    commit
  }, payload) {
    commit('setLoadingIco', true)
    const request = await this.$generateApiRequest()
    const ico = await request.$get('acc/ico/' + payload.id, {})
    commit('setIco', ico.answer.ico)
    commit('setIcoScreenshots', ico.answer.screenshots)
    commit('setLoadingIco', false)
  },
  async createIco({
    commit
  }, payload) {
    commit('setLoadingIco', true)
    const request = await this.$generateApiRequest()
    const response = await request.$post('acc/ico', payload)
    commit('setIco', response.answer.ico)
    commit('setLoadingIco', false)
  },
  async updateIco({
    commit
  }, payload) {
    commit('setLoadingIco', true)
    const request = await this.$generateApiRequest()
    const response = await request.$put('acc/ico/' + payload.id, payload)
    commit('setIco', response.answer.ico)
    commit('setLoadingIco', false)
  },
  async uploadImage({
    commit
  }, payload) {
    commit('setLoadingIco', true)
    return await new Promise((resolve, reject) => {
      this.$upload.$post('acc/ico/upload', payload) // payload shoud be FormData()
        .then((response) => {
          if (response.answer !== undefined) {
            commit('setIcoImage', response.answer.path)
          } else {
            commit('setIcoImage', null)
          }
          commit('setLoadingIco', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoadingIco', false)
          if (error.response && error.response.status < 500) {
            const errors = error.response.data.errors
            reject(this.getErrorsText(errors))
          } else {
            reject(error.message)
          }
          reject(error.config)
        })
    })
  },
  async deleteIco({
    commit
  }, payload) {
    commit('setLoadingIco', true)
    const request = await this.$generateApiRequest()
    await request.$delete('acc/ico/' + payload.id, {})
    commit('removeIco', payload.id)
    commit('setLoadingIco', false)
  },
  async checkIcoUri({
    commit
  }, payload) {
    commit('setLoadingIco', true)
    const request = await this.$generateApiRequest()
    await request.$get('acc/ico/check-uri', {
      params: {
        uri: payload.uri !== undefined ? payload.uri : ''
      }
    })
    commit('setLoadingIco', false)
  }
}

export const getters = {
  icos: state => state.icos,
  total: state => state.total,
  loading: state => state.loading,
  ico: state => state.ico,
  related: state => state.related,
  icoLoading: state => state.icoLoading
}
