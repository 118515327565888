export const state = () => ({
  faqs: [],
  total: 0,
  loading: false,
  faq: null,
  faqLoading: false
})

export const mutations = {
  setFaqs(state, faqs) {
    state.faqs = faqs
  },
  setTotal(state, total) {
    state.total = total
  },
  addFaqs(state, faqs) {
    state.faqs = state.faqs.concat(faqs)
  },
  setFaq(state, faq) {
    state.faq = faq
  },
  setLoadingFaqs(state, status) {
    state.loading = status
  },
  setLoadingFaq(state, status) {
    state.faqLoading = status
  }
}

export const actions = {
  async fetchFaqs({
    commit
  }, payload) {
    commit('setLoadingFaqs', true)
    const request = await this.$generateApiRequest()
    const faqs = await request.$get('faq', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 25,
        filter: payload.filter !== undefined ? payload.filter : '',
        type: payload.type !== undefined ? payload.type : '',
        search: payload.search !== undefined ? payload.search : '',
        language: this.$i18n.locale
      }
    })
    commit('setFaqs', faqs.answer.faqs)
    commit('setTotal', faqs.total)
    commit('setLoadingFaqs', false)
  },
  async fetchFaq({
    commit
  }, payload) {
    commit('setLoadingFaq', true)
    const request = await this.$generateApiRequest()
    const faq = await request.$get('faq/' + payload.uri, {})
    commit('setFaq', faq.answer.faq)
    commit('setLoadingFaq', false)
  }
}

export const getters = {
  faqs: state => state.faqs,
  total: state => state.total,
  loading: state => state.loading,
  faq: state => state.faq,
  faqLoading: state => state.faqLoading
}
