export const state = () => ({
  setting: null,
  settingLoading: false
})

export const mutations = {
  setSetting(state, setting) {
    state.setting = setting
  },
  setLoadingSetting(state, status) {
    state.settingsLoading = status
  }
}

export const actions = {
  async fetchSetting({
    commit
  }, payload) {
    commit('setLoadingSetting', true)
    const request = await this.$generateApiRequest()
    const settings = await request.$get('settings/' + payload.name, {})
    commit('setSetting', settings.answer.setting)
    commit('setLoadingSetting', false)
  }
}

export const getters = {
  setting: state => state.setting,
  settingLoading: state => state.settingLoading
}
