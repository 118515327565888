export const state = () => ({
  seos: [],
  seosLoading: false,
  contacts: null,
  stats: null,
  contactsLoading: false,
  statsLoading: false
})

export const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts
  },
  setStats(state, stats) {
    state.stats = stats
  },
  setSeos(state, seos) {
    state.seos[seos.uri] = seos.page
  },
  setLoadingContacts(state, status) {
    state.contactsLoading = status
  },
  setLoadingStats(state, status) {
    state.statsuLoading = status
  },
  setLoadingSeos(state, status) {
    state.seosLoading = status
  }
}

export const actions = {
  async fetchContacts({
    commit
  }) {
    commit('setLoadingContacts', true)
    const request = await this.$generateApiRequest()
    const contacts = await request.$get('contacts', {
      params: {
        locale: this.$i18n.locale
      }
    }).catch((err) => {
      console.error(err)
    })
    commit('setContacts', contacts.answer.contacts)
    commit('setLoadingContacts', false)
  },
  async fetchStats({
    commit
  }) {
    commit('setLoadingStats', true)
    const request = await this.$generateApiRequest()
    const contacts = await request.$get('stats', {}).catch((err) => {
      console.error(err)
    })
    commit('setStats', contacts.answer.stats)
    commit('setLoadingStats', false)
  },
  async fetchSeos({
    commit
  }, payload) {
    commit('setLoadingSeos', true)
    const request = await this.$generateApiRequest()
    const pageName = payload.uri.split('___')
    const metaPage = await request.$get('seos/' + pageName[0], {
      params: {
        locale: this.$i18n.locale
      }
    })
    commit('setSeos', {
      page: metaPage.answer.page,
      uri: pageName[0]
    })
    commit('setLoadingSeos', false)
  }
}

export const getters = {
  seos: state => state.seos,
  seosLoading: state => state.seosLoading,
  seosByUri: state => (uri) => {
    return state.seos[uri] !== undefined ? state.seos[uri] : null
  },
  contacts: state => state.contacts,
  contactsLoading: state => state.contactsLoading,
  stats: state => state.stats,
  statsLoading: state => state.statsLoading
}
