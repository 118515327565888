export const state = () => ({
  levels: [],
  level: null,
  total: 0,
  levelLoading: false,
  levelsLoading: false
})

export const mutations = {
  setLevel(state, level) {
    state.level = level
  },
  setLevels(state, levels) {
    state.levels = levels
  },
  setTotal(state, total) {
    state.total = total
  },
  setLoadingLevels(state, status) {
    state.levelsLoading = status
  },
  setLoadingLevel(state, status) {
    state.levelLoading = status
  }
}

export const actions = {
  async fetchLevels({
    commit
  }, payload) {
    commit('setLoadingLevels', true)
    const request = await this.$generateApiRequest()
    const levels = await request.$get('bonus-levels', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 25
      }
    })
    commit('setLevels', levels.answer.levels)
    commit('setTotal', levels.answer.total)
    commit('setLoadingLevels', false)
  },
  async fetchCurrentLevel({
    commit
  }, payload) {
    commit('setLoadingLevel', true)
    const request = await this.$generateApiRequest()
    const levels = await request.$get('bonus-levels/current')
    commit('setLevel', levels.answer.level)
    commit('setLoadingLevel', false)
  }
}

export const getters = {
  levels: state => state.levels,
  level: state => state.level,
  total: state => state.total,
  levelLoading: state => state.levelLoading,
  levelsLoading: state => state.levelsLoading
}
