export const state = () => ({
  projects: [],
  total: 0,
  loading: false,
  project: null,
  quotes: [],
  volumes: null,
  projectLoading: false,
  assetsLoading: false,
  searchesLoading: false,
  searches: {
    coins: [],
    defi: [],
    exchanges: []
  },
  platforms: [],
  algorithms: [],
  trendings: [],
  gainers: [],
  recent: [],
  related: [],
  tokens: [],
  cmcData: null,
  totalGainers: 0,
  totalRecent: 0,
  totalTrendings: 0,
  tops: {
    trendings: [],
    gainers: [],
    recent: []
  },
  topsLoading: false,
  totals: {
    cap: 0,
    capPercent: 0,
    volume: 0,
    defiVolume: 0,
    defiVolumePercent: 0
  },
  totalsLoading: false,
  watchlist: [],
  watchlistLoading: false,
  pairs: [],
  pairsLoading: false,
  pairsTotal: 0,
  watchlistTotal: 0
})

export const mutations = {
  setProjects(state, projects) {
    state.projects = projects
  },
  setTotal(state, total) {
    state.total = total
  },
  addProjects(state, projects) {
    state.projects = state.projects.concat(projects)
  },
  setProject(state, project) {
    state.project = project
  },
  setQuotes(state, quotes) {
    state.quotes = quotes
  },
  setPlatforms(state, projects) {
    state.platforms = projects
  },
  setAlgorithms(state, projects) {
    state.algorithms = projects
  },
  setTrendings(state, projects) {
    state.trendings = projects
  },
  setGainers(state, projects) {
    state.gainers = projects
  },
  setRecent(state, projects) {
    state.recent = projects
  },
  setTotalGainers(state, totals) {
    state.totalGainers = totals
  },
  setTotalRecent(state, totals) {
    state.totalRecent = totals
  },
  setTotalTrendings(state, totals) {
    state.totalTrendings = totals
  },
  setWatchlist(state, projects) {
    state.watchlist = projects
  },
  addWatchlist(state, projects) {
    state.watchlist = state.watchlist.concat(projects)
  },
  setTokens(state, tokens) {
    state.tokens = tokens
  },
  setTops(state, tops) {
    state.tops.trendings = tops.trending
    state.tops.gainers = tops.gainers
    state.tops.recent = tops.recently_added
  },
  setTotals(state, totals) {
    state.totals = totals
    state.totals.capPercent = parseFloat(totals.capPercent)
    state.totals.defiVolumePercent = parseFloat(totals.defiVolumePercent)
  },
  setSearches(state, searches) {
    state.searches = {
      coins: searches.coins,
      defi: searches.defi,
      exchanges: searches.exchanges
    }
  },
  unsetSearches(state, searches) {
    state.searches = {
      coins: [],
      defi: [],
      exchanges: []
    }
  },
  setWatchlistTotal(state, total) {
    state.watchlistTotal = total
  },
  setRelated(state, projects) {
    state.related = projects
  },
  setCmcData(state, newData) {
    state.cmcData = newData
  },
  setVolumes(state, volumes) {
    state.volumes = volumes
  },
  setPairs(state, pairs) {
    state.pairs = pairs
  },
  addPairs(state, pairs) {
    state.pairs = state.pairs.concat(pairs)
  },
  setPairsTotal(state, total) {
    state.pairsTotal = total
  },
  setLoadingProjects(state, status) {
    state.loading = status
  },
  setLoadingWatchlist(state, status) {
    state.watchlistLoading = status
  },
  setLoadingProject(state, status) {
    state.projectLoading = status
  },
  setLoadingAssets(state, status) {
    state.assetsLoading = status
  },
  setLoadingSearch(state, status) {
    state.searchesLoading = status
  },
  setLoadingTops(state, status) {
    state.topsLoading = status
  },
  setLoadingTotals(state, status) {
    state.totalsLoading = status
  },
  setLoadingPairs(state, status) {
    state.pairsLoading = status
  }
}

export const actions = {
  async fetchProjects({
    commit
  }, payload) {
    commit('setLoadingProjects', true)
    const request = await this.$generateApiRequest()
    const projects = await request.$get('projects', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        order: payload.order !== undefined ? payload.order : 'desc',
        orderField: payload.orderField !== undefined ? payload.orderField : 'cmc_rank',
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        algorithm: payload.algorithm !== undefined ? payload.algorithm : null,
        category: payload.category !== undefined ? payload.category : null,
        type: payload.type !== undefined ? payload.type : null,
        platform: payload.platform !== undefined ? payload.platform : null,
        marketCap: payload.marketCap !== undefined ? payload.marketCap : null,
        volume: payload.volume !== undefined ? payload.volume : null,
        price: payload.price !== undefined ? payload.price : null,
        priceChange: payload.priceChange !== undefined ? payload.priceChange : null,
        supply: payload.supply !== undefined ? payload.supply : null,
        watchlist: payload.watchlist !== undefined ? payload.watchlist : null,
        search: payload.search !== undefined ? payload.search : null,
        language: this.$i18n.locale
      }
    })
    commit('setProjects', projects.answer.projects)
    commit('setTotal', projects.total)
    commit('setLoadingProjects', false)
  },
  async fetchRelated({
    commit
  }, payload) {
    commit('setLoadingProjects', true)
    const request = await this.$generateApiRequest()
    const projects = await request.$get('projects/related', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        type: payload.type !== undefined ? payload.type : null,
        except: payload.except !== undefined ? payload.except : null,
        language: this.$i18n.locale
      }
    })
    commit('setRelated', projects.answer.projects)
    commit('setLoadingProjects', false)
  },
  async fetchProject({
    commit
  }, payload) {
    commit('setLoadingProject', true)
    const request = await this.$generateApiRequest()
    const project = await request.$get('projects/' + payload.slug)
    commit('setProject', project.answer.project)
    commit('setQuotes', project.answer.quotes)
    commit('setLoadingProject', false)
  },
  async fetchProjectVolumes({
    commit
  }, payload) {
    commit('setLoadingProject', true)
    const request = await this.$generateApiRequest()
    const volumes = await request.$get('projects/' + payload.slug + '/volumes')
    commit('setVolumes', volumes.answer.volumes)
    commit('setLoadingProject', false)
  },
  async fetchPlatforms({
    commit
  }, payload) {
    commit('setLoadingAssets', true)
    const request = await this.$generateApiRequest()
    const project = await request.$get('projects/platforms')
    commit('setPlatforms', project.answer.platforms)
    commit('setLoadingAssets', false)
  },
  async fetchAlgorithms({
    commit
  }, payload) {
    commit('setLoadingAssets', true)
    const request = await this.$generateApiRequest()
    const project = await request.$get('projects/algorithms')
    commit('setAlgorithms', project.answer.algorithms)
    commit('setLoadingAssets', false)
  },
  async fetchTrendings({
    commit
  }, payload) {
    commit('setLoadingAssets', true)
    const request = await this.$generateApiRequest()
    const project = await request.$get('trending', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 50,
        search: payload.search !== undefined ? payload.search : ''
      }
    })
    commit('setTrendings', project.answer.trendings)
    commit('setTotalTrendings', project.answer.total)
    commit('setLoadingAssets', false)
  },
  async fetchRecent({
    commit
  }, payload) {
    commit('setLoadingAssets', true)
    const request = await this.$generateApiRequest()
    const project = await request.$get('recent', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 50,
        search: payload.search !== undefined ? payload.search : ''
      }
    })
    commit('setRecent', project.answer.recent)
    commit('setTotalRecent', project.answer.total)
    commit('setLoadingAssets', false)
  },
  async fetchGainers({
    commit
  }, payload) {
    commit('setLoadingAssets', true)
    const request = await this.$generateApiRequest()
    const project = await request.$get('gainers', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 50,
        search: payload.search !== undefined ? payload.search : ''
      }
    })
    commit('setGainers', project.answer.gainers)
    commit('setTotalGainers', project.answer.total)
    commit('setLoadingAssets', false)
  },
  async fetchGlobalSearch({
    commit
  }, payload) {
    commit('setLoadingSearch', true)
    const request = await this.$generateApiRequest()
    const searches = await request.$get('projects/search', {
      params: {
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setSearches', searches.answer)
    commit('setLoadingSearch', false)
  },
  async fetchTops({
    commit
  }, payload) {
    commit('setLoadingTops', true)
    const request = await this.$generateApiRequest()
    const tops = await request.$get('projects/tops')
    commit('setTops', tops.answer.tops)
    commit('setLoadingTops', false)
  },
  async fetchTotals({
    commit
  }, payload) {
    commit('setLoadingTotals', true)
    const request = await this.$generateApiRequest()
    const totals = await request.$get('projects/totals')
    commit('setTotals', totals.answer.totals)
    commit('setLoadingTotals', false)
  },
  async fetchPairs({
    commit
  }, payload) {
    commit('setLoadingPairs', true)
    const request = await this.$generateApiRequest()
    const totals = await request.$get('projects/pairs', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'rank',
        category: payload.category !== undefined ? payload.category : null,
        fee: payload.fee !== undefined ? payload.fee : null,
        pair: payload.pair !== undefined ? payload.pair : null,
        eid: payload.eid !== undefined ? payload.eid : null,
        pid: payload.pid !== undefined ? payload.pid : null
      }
    })
    if (payload.page === 1) {
      commit('setPairs', totals.answer.pairs)
    } else {
      commit('addPairs', totals.answer.pairs)
    }
    commit('setPairsTotal', totals.answer.total)
    commit('setLoadingPairs', false)
  },
  async fetchCmcData({
    commit
  }, payload) {
    commit('setLoadingTotals', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('tokens/data/cmc', {
      params: {
        id: payload.id,
        range: payload.range !== undefined ? payload.range : 'all'
      }
    })
    commit('setCmcData', response.answer.chartData)
    commit('setLoadingTotals', false)
  },
  async fetchWatchlist({
    commit
  }, payload) {
    commit('setLoadingWatchlist', true)
    const request = await this.$generateApiRequest()
    const projects = await request.$get('acc/projects/watchlist', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        order: payload.order !== undefined ? payload.order : 'desc',
        orderField: payload.orderField !== undefined ? payload.orderField : 'cmc_rank',
        algorithm: payload.algorithm !== undefined ? payload.algorithm : null,
        category: payload.category !== undefined ? payload.category : null,
        platform: payload.platform !== undefined ? payload.platform : null,
        search: payload.search !== undefined ? payload.search : null,
        language: this.$i18n.locale
      }
    })
    commit('setWatchlist', projects.answer.projects)
    commit('setWatchlistTotal', projects.total)
    commit('setLoadingWatchlist', false)
  },
  async searchTokens({
    commit
  }, payload) {
    commit('setLoadingProjects', true)
    const request = await this.$generateApiRequest()
    const projects = await request.$get('projects/search-tokens', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 10,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setTokens', projects.answer)
    commit('setLoadingProjects', false)
  }
}

export const getters = {
  projects: state => state.projects,
  total: state => state.total,
  totalRecent: state => state.totalRecent,
  totalGainers: state => state.totalGainers,
  totalTrendings: state => state.totalTrendings,
  loading: state => state.loading,
  platforms: state => state.platforms,
  algorithms: state => state.algorithms,
  trendings: state => state.trendings,
  gainers: state => state.gainers,
  recent: state => state.recent,
  searches: state => state.searches,
  project: state => state.project,
  quotes: state => state.quotes,
  watchlist: state => state.watchlist,
  tokens: state => state.tokens,
  tops: state => state.tops,
  totals: state => state.totals,
  related: state => state.related,
  cmcData: state => state.cmcData,
  volumes: state => state.volumes,
  pairs: state => state.pairs,
  pairsTotal: state => state.pairsTotal,
  pairsLoading: state => state.pairsLoading,
  watchlistTotal: state => state.watchlistTotal,
  projectLoading: state => state.projectLoading,
  assetsLoading: state => state.assetsLoading,
  searchesLoading: state => state.searchesLoading,
  topsLoading: state => state.topsLoading,
  watchlistLoading: state => state.watchlistLoading,
  totalsLoading: state => state.totalsLoading
}
