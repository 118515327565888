export const state = () => ({
  drops: [],
  total: 0,
  loading: false,
  drop: null,
  dropLoading: false
})

export const mutations = {
  setDrops(state, drops) {
    state.drops = drops
  },
  setDrop(state, drop) {
    state.drop = drop
  },
  setTotal(state, total) {
    state.total = total
  },
  addDrops(state, drops) {
    state.drops = state.drops.concat(drops)
  },
  setLoadingDrops(state, status) {
    state.loading = status
  },
  removeDrop(state, id) {
    state.drops = state.drops.filter(itm => itm.id !== id)
  },
  setLoadingDrop(state, status) {
    state.dropLoading = status
  },
  setDropImage(state, image) {
    if (state.drop !== null) {
      state.drop.image = image
    }
  }
}

export const actions = {
  async fetchDrops({
    commit
  }, payload) {
    commit('setLoadingDrops', true)
    const request = await this.$generateApiRequest()
    const drops = await request.$get('drops', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        uid: payload.uid !== undefined ? payload.uid : null,
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        search: payload.search !== undefined ? payload.search : null,
        category: payload.category !== undefined ? payload.category : null,
        date: payload.date !== undefined ? payload.date : null,
        priceFrom: payload.priceFrom !== undefined ? payload.priceFrom : null,
        priceTo: payload.priceTo !== undefined ? payload.priceTo : null,
        supplyFrom: payload.supplyFrom !== undefined ? payload.supplyFrom : null,
        supplyTo: payload.supplyTo !== undefined ? payload.supplyTo : null
      }
    })
    commit('setDrops', drops.answer.drops)
    commit('setTotal', drops.total)
    commit('setLoadingDrops', false)
  },
  async fetchMyDrops({
    commit
  }, payload) {
    commit('setLoadingDrops', true)
    const request = await this.$generateApiRequest()
    const drops = await request.$get('acc/drops', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        uid: payload.uid !== undefined ? payload.uid : null,
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        search: payload.search !== undefined ? payload.search : null,
        category: payload.category !== undefined ? payload.category : null,
        date: payload.date !== undefined ? payload.date : null,
        priceFrom: payload.priceFrom !== undefined ? payload.priceFrom : null,
        priceTo: payload.priceTo !== undefined ? payload.priceTo : null,
        supplyFrom: payload.supplyFrom !== undefined ? payload.supplyFrom : null,
        supplyTo: payload.supplyTo !== undefined ? payload.supplyTo : null
      }
    })
    commit('setDrops', drops.answer.drops)
    commit('setTotal', drops.answer.total)
    commit('setLoadingDrops', false)
  },
  async fetchDropEdit({
    commit
  }, payload) {
    commit('setLoadingDrop', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('acc/drops/' + payload.id, {})
    commit('setDrop', response.answer.drop)
    commit('setLoadingDrop', false)
  },
  async createDrop({
    commit
  }, payload) {
    commit('setLoadingDrop', true)
    const request = await this.$generateApiRequest()
    const response = await request.$post('acc/drops', payload)
    commit('setDrop', response.answer.drop)
    commit('setLoadingDrop', false)
  },
  async updateDrop({
    commit
  }, payload) {
    commit('setLoadingDrop', true)
    const request = await this.$generateApiRequest()
    const response = await request.$put('acc/drops/' + payload.id, payload)
    commit('setDrop', response.answer.drop)
    commit('setLoadingDrop', false)
  },
  async uploadImage({
    commit
  }, payload) {
    commit('setLoadingDrop', true)
    return await new Promise((resolve, reject) => {
      this.$upload.$post('acc/drops/upload', payload) // payload shoud be FormData()
        .then((response) => {
          if (response.answer !== undefined) {
            commit('setDropImage', response.answer.path)
          } else {
            commit('setDropImage', null)
          }
          commit('setLoadingDrop', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoadingDrop', false)
          if (error.response && error.response.status < 500) {
            const errors = error.response.data.errors
            reject(this.getErrorsText(errors))
          } else {
            reject(error.message)
          }
          reject(error.config)
        })
    })
  },
  async deleteDrop({
    commit
  }, payload) {
    commit('setLoadingDrop', true)
    const request = await this.$generateApiRequest()
    await request.$delete('acc/drops/' + payload.id, {})
    commit('removeDrop', payload.id)
    commit('setLoadingDrop', false)
  },
  async checkDropUri({
    commit
  }, payload) {
    commit('setLoadingDrop', true)
    const request = await this.$generateApiRequest()
    await request.$get('acc/drops/check-uri', {
      params: {
        uri: payload.uri !== undefined ? payload.uri : ''
      }
    })
    commit('setLoadingDrop', false)
  }
}

export const getters = {
  drops: state => state.drops,
  drop: state => state.drop,
  total: state => state.total,
  loading: state => state.loading,
  dropLoading: state => state.dropLoading
}
