export const state = () => ({
  loading: false
})

export const mutations = {
  setLoading(state, status) {
    state.loading = status
  }
}

export const actions = {
  async sendCallback({
    commit
  }, payload) {
    commit('setLoading', true)
    const request = await this.$generateApiRequest()
    await request.$post('support/callback', {
      email: payload.email !== undefined ? payload.email : '',
      message: payload.message !== undefined ? payload.message : '',
      recaptcha: payload.recaptcha !== undefined ? payload.recaptcha : ''
    })
    commit('setLoading', false)
  },
  async sendSubscribe({
    commit
  }, payload) {
    commit('setLoading', true)
    const request = await this.$generateApiRequest()
    await request.$post('support/subscribe', {
      email: payload.email !== undefined ? payload.email : '',
      recaptcha: payload.recaptcha !== undefined ? payload.recaptcha : ''
    })
    commit('setLoading', false)
  }
}

export const getters = {
  loading: state => state.loading
}
