export const state = () => ({
  nfts: [],
  total: 0,
  loading: false,
  nft: null,
  nftLoading: false,
  related: []
})

export const mutations = {
  setNfts(state, nfts) {
    state.nfts = nfts
  },
  setTotal(state, total) {
    state.total = total
  },
  addNfts(state, nfts) {
    state.nfts = state.nfts.concat(nfts)
  },
  setNft(state, nft) {
    state.nft = nft
  },
  setRelated(state, related) {
    state.related = related
  },
  setLoadingNfts(state, status) {
    state.loading = status
  },
  setLoadingNft(state, status) {
    state.nftLoading = status
  }
}

export const actions = {
  async fetchNfts({
    commit
  }, payload) {
    commit('setLoadingNfts', true)
    const request = await this.$generateApiRequest()
    const nfts = await request.$get('nft', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        order: payload.order !== undefined ? payload.order : 'desc',
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        period: payload.period !== undefined ? payload.period : null,
        verified: payload.verified !== undefined ? payload.verified : null,
        uid: payload.uid !== undefined ? payload.uid : null,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setNfts', nfts.answer.nfts)
    commit('setTotal', nfts.total)
    commit('setLoadingNfts', false)
  },
  async fetchNft({
    commit
  }, payload) {
    commit('setLoadingNft', true)
    const request = await this.$generateApiRequest()
    const nft = await request.$get('nft/' + payload.uri, {})
    commit('setNft', nft.answer.nft)
    commit('setLoadingNft', false)
  },
  async fetchRelated({
    commit
  }, payload) {
    commit('setLoadingNfts', true)
    const request = await this.$generateApiRequest()
    const nft = await request.$get('nft/' + payload.uri + '/related')
    commit('setRelated', nft.answer.nfts)
    commit('setLoadingNfts', false)
  }
}

export const getters = {
  nfts: state => state.nfts,
  total: state => state.total,
  loading: state => state.loading,
  nft: state => state.nft,
  related: state => state.related,
  nftLoading: state => state.nftLoading
}
