export const state = () => ({
  user: null,
  details: null,
  userToken: '',
  watchlist: [],
  watchlistLoading: false,
  watchlistChanging: null,
  userLoading: false
})

export const mutations = {
  setUser(state, user) {
    state.user = user
  },
  addUserBalance(state, amount) {
    state.user.usdt_balance += amount
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', state.user, {
      expires: nowDate
    })
  },
  setUserDetails(state, details) {
    state.details = details
  },
  setUserImage(state, image) {
    state.user.avatar = 'users/' + image
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', state.user, {
      expires: nowDate
    })
  },
  setUserToken(state, userToken) {
    state.userToken = userToken
  },
  setWatchlist(state, watchlist) {
    state.watchlist = watchlist
  },
  addToWatchlist(state, watchlist) {
    state.watchlist.unshift(watchlist)
  },
  removeFromWatchlist(state, watchlist) {
    state.watchlist = state.watchlist.filter(item => item !== watchlist)
  },
  setUserAddress(state, address) {
    if (state.user !== undefined && state.user !== null) {
      state.user.address = address
    }
  },
  unsetUser(state) {
    state.user = null
  },
  unsetUserToken(state) {
    state.userToken = ''
  },
  setLoadingUser(state, status) {
    state.userLoading = status
  },
  setWatchlistLoading(state, status) {
    state.watchlistLoading = status
  },
  setWatchlistChanging(state, pid) {
    state.watchlistChanging = pid
  }
}

export const actions = {
  async checkAuth({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$get('acc/auth/check', payload)
    commit('setLoadingUser', false)
  },
  async register({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('register', payload)
    commit('setUser', userData.answer.user)
    commit('setUserToken', userData.answer.token)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    this.$cookies.set('auth.token', userData.answer.token, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async login({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('login', payload)
    if (userData.answer.user !== undefined) {
      commit('setUser', userData.answer.user)
      commit('setUserToken', userData.answer.token)
      const nowDate = new Date()
      nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
      this.$cookies.set('auth.user', userData.answer.user, {
        expires: nowDate
      })
      this.$cookies.set('auth.token', userData.answer.token, {
        expires: nowDate
      })
    }
    commit('setLoadingUser', false)
  },
  async login2fa({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('login/2fa', payload)
    commit('setUser', userData.answer.user)
    commit('setUserToken', userData.answer.token)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    this.$cookies.set('auth.token', userData.answer.token, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async loginFacebook({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('login/facebook', payload)
    commit('setUser', userData.answer.user)
    commit('setUserToken', userData.answer.token)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    this.$cookies.set('auth.token', userData.answer.token, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async loginLinkedin({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('login/linkedin', payload)
    commit('setUser', userData.answer.user)
    commit('setUserToken', userData.answer.token)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    this.$cookies.set('auth.token', userData.answer.token, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async logout({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$post('acc/logout', payload)
    commit('unsetUser')
    commit('unsetUserToken')
    this.$cookies.remove('auth.token')
    this.$cookies.remove('auth.user')
    commit('setLoadingUser', false)
  },
  async checkWatchlist({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$get('acc/watchlist/' + payload.slug, {})
      .then(() => {
        commit('addToWatchlist', payload.slug)
      })
    commit('setLoadingUser', false)
  },
  async fetchWatchlist({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userList = await request.$get('acc/watchlist')
    commit('setWatchlist', userList.answer.watchlist)
    commit('setLoadingUser', false)
  },
  async addToWatchlist({
    commit
  }, payload) {
    commit('setWatchlistLoading', true)
    commit('setWatchlistChanging', payload.id)
    const request = await this.$generateApiRequest()
    await request.$post('acc/watchlist', {
      id: payload.id !== undefined ? payload.id : null,
      model: payload.model !== undefined ? payload.model : null
    })
      .then(() => {
        commit('addToWatchlist', payload.id)
        commit('setWatchlistChanging', null)
      })
    commit('setWatchlistLoading', false)
  },
  async removeFromWatchlist({
    commit
  }, payload) {
    commit('setWatchlistLoading', true)
    commit('setWatchlistChanging', payload.id)
    const request = await this.$generateApiRequest()
    await request.$delete('acc/watchlist/', {
      params: {
        id: payload.id !== undefined ? payload.id : null,
        model: payload.model !== undefined ? payload.model : null
      }
    })
      .then(() => {
        commit('setWatchlistChanging', null)
        commit('removeFromWatchlist', payload.id)
      })
    commit('setWatchlistLoading', false)
  },
  async resendEmail({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$post('acc/profile/resend')
    commit('setLoadingUser', false)
  },
  async resendPassword({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$post('forgot', {
      email: payload.email !== undefined ? payload.email : ''
    })
    commit('setLoadingUser', false)
  },
  async resetPassword({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('reset', {
      token: payload.token !== undefined ? payload.token : '',
      password: payload.password !== undefined ? payload.password : '',
      password_confirmation: payload.password_confirmation !== undefined ? payload.password_confirmation : '',
      recaptcha: payload.recaptcha !== undefined ? payload.recaptcha : ''
    })
    this.$cookies.remove('auth.token')
    this.$cookies.remove('auth.user')
    commit('setUser', userData.answer.user)
    commit('setUserToken', userData.answer.token)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    this.$cookies.set('auth.token', userData.answer.token, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async updateAvatar({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    return await new Promise((resolve, reject) => {
      this.$upload.$post('acc/profile/upload', payload) // payload shoud be FormData()
        .then((response) => {
          if (response.answer !== undefined) {
            commit('setUserImage', response.answer.path)
          } else {
            commit('setUserImage', null)
          }
          commit('setLoadingUser', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoadingUser', false)
          if (error.response && error.response.status < 500) {
            const errors = error.response.data.errors
            reject(this.getErrorsText(errors))
          } else {
            reject(error.message)
          }
          reject(error.config)
        })
    })
  },
  async updatePassword({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$put('acc/profile/password', payload)
    commit('setLoadingUser', false)
  },
  async updateProfile({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$put('acc/profile/update', payload)
    commit('setUser', userData.answer.user)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async topupAccountBalance({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$post('acc/profile/topup', payload)
    commit('addUserBalance', payload.amount)
    commit('setLoadingUser', false)
  },
  async refreshProfile({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$get('acc/profile')
    commit('setUser', userData.answer.user)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async updateProfileDetails({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$put('acc/profile/update-details', payload)
    commit('setUserDetails', userData.answer.details)
    commit('setLoadingUser', false)
  },
  async updateAddress({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$put('acc/profile/update-address', payload)
    commit('setUser', userData.answer.user)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async getProfileDetails({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$get('acc/profile/details', payload)
    commit('setUserDetails', userData.answer.details)
    commit('setLoadingUser', false)
    return userData.answer.details
  },
  async get2faQrCode({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$get('acc/profile/2fa/qr', payload)
    commit('setLoadingUser', false)
    return userData.answer
  },
  async set2fa({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$put('acc/profile/2fa', payload)
    commit('setUser', userData.answer.user)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async delete2fa({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$delete('acc/profile/2fa', {
      params: {
        password: payload.password
      }
    })
    commit('setUser', userData.answer.user)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async generatePaymentLink({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('acc/profile/payment_link', {
      amount: payload.amount !== undefined && payload.amount !== null ? payload.amount : 0,
      currency: payload.currency !== undefined && payload.currency !== null ? payload.currency : 'USD',
      type: payload.type !== undefined && payload.type !== null ? payload.type : 'ads',
      ps: payload.ps !== undefined && payload.ps !== null ? payload.ps : 'payeer',
      tokens: payload.tokens !== undefined && payload.tokens !== null ? payload.tokens : null,
      network: payload.network !== undefined && payload.network !== null ? payload.network : '2609',
      description: payload.description !== undefined && payload.description !== null ? payload.description : ''
    })
    commit('setLoadingUser', false)
    return userData.answer.link
  },
  async investInCrt({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('acc/profile/invest', {
      amount: payload.amount !== undefined && payload.amount !== null ? payload.amount : 0,
      currency: payload.currency !== undefined && payload.currency !== null ? payload.currency : 'USD',
      type: payload.type !== undefined && payload.type !== null ? payload.type : 'ads',
      ps: payload.ps !== undefined && payload.ps !== null ? payload.ps : 'payeer',
      tokens: payload.tokens !== undefined && payload.tokens !== null ? payload.tokens : null,
      network: payload.network !== undefined && payload.network !== null ? payload.network : '2609',
      description: payload.description !== undefined && payload.description !== null ? payload.description : ''
    })
    commit('setUser', userData.answer.user)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
    return userData.answer.investment
  },
  async downloadCoinqvestCurrencies({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$get('acc/coinqvest/currencies')
    commit('setLoadingUser', false)
    return userData.answer.currencies
  }
}

export const getters = {
  user: state => state.user,
  details: state => state.details,
  userToken: state => state.userToken,
  userLoading: state => state.userLoading,
  watchlist: state => state.watchlist,
  watchlistChanging: state => state.watchlistChanging,
  watchlistLoading: state => state.watchlistLoading
}
