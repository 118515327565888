export const state = () => ({
  p2es: [],
  total: 0,
  loading: false,
  p2e: null,
  p2eLoading: false,
  related: [],
  tops: {
    trending: [],
    gainers: [],
    airdrops: []
  },
  topsLoading: false
})

export const mutations = {
  setP2es(state, p2es) {
    state.p2es = p2es
  },
  setTotal(state, total) {
    state.total = total
  },
  addP2es(state, p2es) {
    state.p2es = state.p2es.concat(p2es)
  },
  setP2e(state, answer) {
    state.p2e = answer.p2e
    if (answer.screenshots !== undefined) {
      state.p2e.screenshots = answer.screenshots
    }
  },
  setP2eScreenshots(state, screenshots) {
    state.p2e.screenshots = screenshots
  },
  removeP2e(state, id) {
    state.p2es = state.p2es.filter(itm => itm.id !== id)
  },
  changeP2eActive(state, id) {
    const p2eIndex = state.p2es.findIndex(itm => itm.id === id)
    if (p2eIndex !== -1) {
      state.p2es[p2eIndex].active = !state.p2es[p2eIndex].active
    }
  },
  setRelated(state, related) {
    state.related = related
  },
  setLoadingP2es(state, status) {
    state.loading = status
  },
  setLoadingP2e(state, status) {
    state.p2eLoading = status
  },
  setLoadingTops(state, status) {
    state.topsLoading = status
  },
  setP2eImage(state, image) {
    if (state.p2e !== null) {
      state.p2e.image = image
    }
  },
  setTops(state, tops) {
    state.tops.trending = tops.trending
    state.tops.gainers = tops.gainers
    state.tops.airdrops = tops.airdrops
  }
}

export const actions = {
  async fetchP2es({
    commit
  }, payload) {
    commit('setLoadingP2es', true)
    const request = await this.$generateApiRequest()
    const p2es = await request.$get('p2e', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        uid: payload.uid !== undefined ? payload.uid : null,
        genre: payload.genre !== undefined ? payload.genre : null,
        device: payload.device !== undefined ? payload.device : null,
        status: payload.status !== undefined ? payload.status : null,
        nft: payload.nft !== undefined ? payload.nft : null,
        f2p: payload.f2p !== undefined ? payload.f2p : null,
        p2e: payload.p2e !== undefined ? payload.p2e : null,
        new: payload.new !== undefined ? payload.new : null,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setP2es', p2es.answer.p2es)
    commit('setTotal', p2es.total)
    commit('setLoadingP2es', false)
  },
  async fetchP2e({
    commit
  }, payload) {
    commit('setLoadingP2e', true)
    const request = await this.$generateApiRequest()
    const p2e = await request.$get('p2e/' + payload.uri, {})
    commit('setP2e', p2e.answer)
    commit('setLoadingP2e', false)
  },
  async addVisit({
    commit
  }, payload) {
    const request = await this.$generateApiRequest()
    request.$put('p2e/' + payload.uri + '/visit', {})
  },
  async addLink({
    commit
  }, payload) {
    const request = await this.$generateApiRequest()
    request.$put('p2e/' + payload.uri + '/link', {})
  },
  async addSearch({
    commit
  }, payload) {
    const request = await this.$generateApiRequest()
    request.$put('p2e/' + payload.uri + '/search', {})
  },
  async fetchTops({
    commit
  }, payload) {
    commit('setLoadingTops', true)
    const request = await this.$generateApiRequest()
    const tops = await request.$get('p2e/tops')
    commit('setTops', tops.answer.tops)
    commit('setLoadingTops', false)
  },
  async fetchMyP2es({
    commit
  }, payload) {
    commit('setLoadingP2es', true)
    const request = await this.$generateApiRequest()
    const p2es = await request.$get('acc/p2e', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        blockchain: payload.blockchain !== undefined ? payload.blockchain : null,
        genre: payload.genre !== undefined ? payload.genre : null,
        device: payload.device !== undefined ? payload.device : null,
        status: payload.status !== undefined ? payload.status : null,
        nft: payload.nft !== undefined ? payload.nft : null,
        f2p: payload.f2p !== undefined ? payload.f2p : null,
        p2e: payload.p2e !== undefined ? payload.p2e : null,
        new: payload.new !== undefined ? payload.new : null,
        search: payload.search !== undefined ? payload.search : null
      }
    })
    commit('setP2es', p2es.answer.p2es)
    commit('setTotal', p2es.total)
    commit('setLoadingP2es', false)
  },
  async fetchP2eEdit({
    commit
  }, payload) {
    commit('setLoadingP2e', true)
    const request = await this.$generateApiRequest()
    const p2e = await request.$get('acc/p2e/' + payload.id, {})
    commit('setP2e', p2e.answer)
    commit('setP2eScreenshots', p2e.answer.screenshots)
    commit('setLoadingP2e', false)
  },
  async createP2e({
    commit
  }, payload) {
    commit('setLoadingP2e', true)
    const request = await this.$generateApiRequest()
    const response = await request.$post('acc/p2e', payload)
    commit('setP2e', response.answer)
    commit('setLoadingP2e', false)
  },
  async updateP2e({
    commit
  }, payload) {
    commit('setLoadingP2e', true)
    const request = await this.$generateApiRequest()
    const response = await request.$put('acc/p2e/' + payload.id, payload)
    commit('setP2e', response.answer)
    commit('setLoadingP2e', false)
  },
  async toggleP2eVisible({
    commit
  }, payload) {
    commit('setLoadingP2e', true)
    const request = await this.$generateApiRequest()
    await request.$put('acc/p2e/' + payload.id + '/toggle')
    commit('changeP2eActive', payload.id)
    commit('setLoadingP2e', false)
  },
  async uploadImage({
    commit
  }, payload) {
    commit('setLoadingP2e', true)
    return await new Promise((resolve, reject) => {
      this.$upload.$post('acc/p2e/upload', payload) // payload shoud be FormData()
        .then((response) => {
          if (response.answer !== undefined) {
            commit('setP2eImage', response.answer.path)
          } else {
            commit('setP2eImage', null)
          }
          commit('setLoadingP2e', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoadingP2e', false)
          if (error.response && error.response.status < 500) {
            const errors = error.response.data.errors
            reject(this.getErrorsText(errors))
          } else {
            reject(error.message)
          }
          reject(error.config)
        })
    })
  },
  async deleteP2e({
    commit
  }, payload) {
    commit('setLoadingP2e', true)
    const request = await this.$generateApiRequest()
    await request.$delete('acc/p2e/' + payload.id, {})
    commit('removeP2e', payload.id)
    commit('setLoadingP2e', false)
  },
  async checkP2eUri({
    commit
  }, payload) {
    commit('setLoadingP2e', true)
    const request = await this.$generateApiRequest()
    await request.$get('acc/p2e/check-uri', {
      params: {
        uri: payload.uri !== undefined ? payload.uri : ''
      }
    })
    commit('setLoadingP2e', false)
  }
}

export const getters = {
  p2es: state => state.p2es,
  total: state => state.total,
  loading: state => state.loading,
  p2e: state => state.p2e,
  tops: state => state.tops,
  topsLoading: state => state.topsLoading,
  related: state => state.related,
  p2eLoading: state => state.p2eLoading
}
