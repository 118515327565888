export const state = () => ({
  articles: [],
  total: 0,
  loading: false,
  article: null,
  articleLoading: false,
  related: []
})

export const mutations = {
  setArticles(state, articles) {
    state.articles = articles
  },
  setTotal(state, total) {
    state.total = total
  },
  addArticles(state, articles) {
    state.articles = state.articles.concat(articles)
  },
  setArticle(state, article) {
    state.article = article
  },
  setRelated(state, related) {
    state.related = related
  },
  setLoadingArticles(state, status) {
    state.loading = status
  },
  setLoadingArticle(state, status) {
    state.articleLoading = status
  }
}

export const actions = {
  async fetchArticles({
    commit
  }, payload) {
    commit('setLoadingArticles', true)
    commit('setArticles', [])
    commit('setTotal', 0)
    const request = await this.$generateApiRequest()
    const articles = await request.$get('news', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
        category: payload.category !== undefined ? payload.category : '',
        type: payload.type !== undefined ? payload.type : '',
        difficulty: payload.difficulty !== undefined ? payload.difficulty : '',
        search: payload.search !== undefined ? payload.search : ''
      }
    })
    commit('setArticles', articles.answer)
    commit('setTotal', articles.total)
    commit('setLoadingArticles', false)
  },
  async fetchProjectArticles({
    commit
  }, payload) {
    commit('setLoadingArticles', true)
    const request = await this.$generateApiRequest()
    const articles = await request.$get('projects/news', {
      params: {
        page: payload.page !== undefined ? payload.page : 1,
        step: payload.step !== undefined ? payload.step : 15,
        pid: payload.pid !== undefined ? payload.pid : 0
      }
    })
    commit('setArticles', articles.answer)
    commit('setTotal', articles.total)
    commit('setLoadingArticles', false)
  },
  async fetchArticle({
    commit
  }, payload) {
    commit('setLoadingArticle', true)
    const request = await this.$generateApiRequest()
    const article = await request.$get('news/' + payload.uri, {})
    commit('setArticle', article.answer.article)
    commit('setLoadingArticle', false)
  },
  async fetchArticleContent({
    commit
  }, payload) {
    commit('setLoadingArticle', true)
    const request = await this.$generateApiRequest()
    const article = await request.$get('news/' + payload.uri + '/content', {})
    commit('setArticle', article.answer.article)
    commit('setLoadingArticle', false)
  },
  async fetchRelated({
    commit
  }, payload) {
    commit('setLoadingArticle', true)
    const request = await this.$generateApiRequest()
    const posts = await request.$get('news', {
      params: {
        locale: this.$i18n.locale,
        category: payload.cid !== undefined ? payload.cid : '',
        article_uri: payload.article_uri !== undefined ? payload.article_uri : '',
        sort: 'rand',
        search: '',
        page: 1,
        step: payload.step !== undefined ? payload.step : 4
      }
    })
    commit('setRelated', posts.answer)
    commit('setLoadingArticle', false)
  }
}

export const getters = {
  articles: state => state.articles,
  total: state => state.total,
  loading: state => state.loading,
  article: state => state.article,
  related: state => state.related,
  articleLoading: state => state.articleLoading
}
