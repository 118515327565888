export const state = () => ({
  rounds: [],
  collected: 0,
  total: 0,
  round: null,
  roundsLoading: false,
  roundLoading: false
})

export const mutations = {
  setRounds(state, rounds) {
    state.rounds = rounds
  },
  setTotal(state, total) {
    state.total = total
  },
  setRound(state, round) {
    state.round = round
  },
  setCollected(state, collected) {
    state.collected = collected
  },
  addRound(state, round) {
    state.rounds.unshift(round)
  },
  addRounds(state, rounds) {
    state.rounds = state.rounds.concat(rounds)
  },
  setLoadingRounds(state, status) {
    state.roundsLoading = status
  },
  setLoadingRound(state, status) {
    state.roundLoading = status
  }
}

export const actions = {
  async fetchRounds({
    commit
  }, payload) {
    commit('setLoadingRounds', true)
    const request = await this.$generateApiRequest()
    const rounds = await request.$get('rounds', {
      params: {
        page: payload !== undefined && payload.page !== undefined ? payload.page : 1,
        step: payload !== undefined && payload.step !== undefined ? payload.step : 25,
        orderField: payload !== undefined && payload.orderField !== undefined ? payload.orderField : 'sort_asc',
        search: payload !== undefined && payload.search !== undefined ? payload.search : null
      }
    })
    commit('setRounds', rounds.answer.rounds)
    commit('setCollected', rounds.answer.collected)
    commit('setTotal', rounds.total)
    commit('setLoadingRounds', false)
  }
}

export const getters = {
  rounds: state => state.rounds,
  collected: state => state.collected,
  total: state => state.total,
  roundsLoading: state => state.roundsLoading,
  roundLoading: state => state.roundLoading
}
